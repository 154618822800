import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "../css/style.css"

const pageQuery = graphql`
  {
    gcms {
      notas(where: { seccionUrl: nfl }, orderBy: createdAt_DESC, skip: 3) {
        titulo
        mainTag
        seccionTitulo
        seccionUrl
        resumen
        url
        imagenes {
          url(transformation: { document: { output: { format: png } } })
        }
      }
    }
  }
`

const Notesnfl = () => {
  const {
    gcms: { notas },
  } = useStaticQuery(pageQuery)

  return (
    <section className="notesseccion-section page-border">
      {notas.map(
        ({ seccionTitulo, seccionUrl, mainTag, url, imagenes, ...notas }) => {
          return (
            <div className="notesseccion-div">
              <div className="grid-container-notesseccion">
                <div className="notesseccion-img-sec">
                  <Link key={url} to={`/${seccionUrl}/${url}`}>
                    <img alt="" src={imagenes.url} />
                  </Link>
                </div>
                <div>
                  <div className="notesseccion-h2-sec">
                    <Link
                      key={url}
                      to={`/${seccionUrl}`}
                      className="notesseccion-div-seccion"
                    >
                      {seccionTitulo}
                    </Link>
                    <br />
                    <Link
                      key={url}
                      to={`/${seccionUrl}/${url}`}
                      className="notesseccion-h3"
                    >
                      {notas.titulo}
                    </Link>
                    <p className="notesseccion-text">{notas.resumen}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      )}
    </section>
  )
}

export default Notesnfl
