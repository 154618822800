import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Topicnfl from "../components/topicnfl"
import Notesnfl from "../components/notesnfl"
import Mainnfl from "../components/mainnfl"

const NFLPage = () => (
  <>
    <SEO title="NFL" />
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <div className="grid-container-templatenota">
      <div className="templatenota-banner-section"></div>
      <div>
        <Mainnfl />
        <Topicnfl />
        <Notesnfl />
      </div>
      <div className="templatenota-banner-section"></div>
    </div>
  </>
)

export default NFLPage
